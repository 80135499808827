//type
import { COUNTRY_CODE } from '@interfaces/graphql/API';

/**
 * Convert country code in nextjs config into country code format
 *
 * @param {string} value - Country code in next.config.js
 * @returns {COUNTRY_CODE} - Formatted country code.
 */

const useFormatCountryCode = (value?: string): COUNTRY_CODE => {
    return value ? (value.split('-')[1].toUpperCase() as COUNTRY_CODE) : COUNTRY_CODE.SG;
};

export default useFormatCountryCode;
