/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export enum COUNTRY_CODE {
  SG = "SG",
  MY = "MY",
}


export type SignUpInput = {
  name: string,
  family_name: string,
  email: string,
  password: string,
  company?: string | null,
};

export type ProfileInput = {
  company: string,
  email: string,
  family_name: string,
  name: string,
};

export type Profile = {
  __typename: "Profile",
  company?: string | null,
  email: string,
  family_name: string,
  name: string,
  view?: VIEW | null,
};

export enum VIEW {
  TABLE = "TABLE",
  GRID = "GRID",
}


export type QuoteInput = {
  capacity: number,
  cost: number,
  panel_number: number,
  module: string,
  inverter: string,
  inverter_number: number,
  scaffolding: SCAFFOLDING,
  generated?: number | null,
  comment?: string | null,
  docs: Array< DocInput | null >,
};

export enum SCAFFOLDING {
  YES = "YES",
  NO = "NO",
  TO_CONFIRM = "TO_CONFIRM",
}


export type DocInput = {
  name: string,
  type: string,
  link: string,
  deleted?: boolean | null,
};

export enum QUOTE_STATUS {
  QUOTE_RECEIVED = "QUOTE_RECEIVED",
  REJECTED = "REJECTED",
}


export type ReportInput = {
  date: string,
  text: string,
};

export type Report = {
  __typename: "Report",
  date: string,
  text: string,
};

export enum SORT {
  ASC = "ASC",
  DESC = "DESC",
}


export enum PROSPECT_SORT_FIELD {
  ADDRESS = "ADDRESS",
  TIME_FRAME = "TIME_FRAME",
  TYPE = "TYPE",
  OCCUPANCY = "OCCUPANCY",
  STATUS = "STATUS",
}


export enum STATUS {
  TIMEOUT = "TIMEOUT",
  PENDING_QUOTE = "PENDING_QUOTE",
  PENDING_REQUOTE = "PENDING_REQUOTE",
  REVIEW = "REVIEW",
  PROPOSAL_SEND = "PROPOSAL_SEND",
  SHORTLISTED = "SHORTLISTED",
  CLOSE_WON = "CLOSE_WON",
  CLOSE_LOST = "CLOSE_LOST",
  NURTURE = "NURTURE",
  REJECTED = "REJECTED",
}


export type ListProspects = {
  __typename: "ListProspects",
  total: number,
  prospects:  Array<Prospect >,
};

export type Prospect = {
  __typename: "Prospect",
  id: string,
  timeframe: number,
  address: string,
  postal_code?: string | null,
  type: BUILDING_TYPE,
  expected_date?: string | null,
  occupancy?: OCCUPANCY | null,
  docs?:  Array<Doc > | null,
  remark?: string | null,
  status: STATUS,
  created: string,
  delivery: string,
  polygon?:  Array<Polygon > | null,
  location?: Array< number > | null,
  quote: Quote,
  index: number,
  comment?: string | null,
};

export enum BUILDING_TYPE {
  LANDED_HOUSE = "LANDED_HOUSE",
  CONDOMINIUM = "CONDOMINIUM",
  HDB_FLAT = "HDB_FLAT",
  RENTED_COMMERCIAL_PROPERTY = "RENTED_COMMERCIAL_PROPERTY",
  OWNED_COMMERCIAL_PROPERTY = "OWNED_COMMERCIAL_PROPERTY",
}


export enum OCCUPANCY {
  OCCUPY = "OCCUPY",
  MOVING_IN = "MOVING_IN",
}


export type Doc = {
  __typename: "Doc",
  link: string,
  name: string,
  type: string,
  category?: DOC_CATEGORY | null,
};

export enum DOC_CATEGORY {
  METER = "METER",
  ROOF = "ROOF",
  PLAN = "PLAN",
  SWITCHBOARD = "SWITCHBOARD",
}


export type Polygon = {
  __typename: "Polygon",
  lng: number,
  lat: number,
};

export type Quote = {
  __typename: "Quote",
  capacity?: number | null,
  cost?: number | null,
  panel_number?: number | null,
  module?: string | null,
  inverter?: string | null,
  inverter_number?: number | null,
  scaffolding?: SCAFFOLDING | null,
  generated?: number | null,
  comment?: string | null,
  docs:  Array<Doc >,
  reports?:  Array<Report > | null,
};

export type File = {
  name: string,
  type: string,
};

export type Upload = {
  __typename: "Upload",
  url: Array< string | null >,
  link: Array< string | null >,
  name: Array< string >,
};

export type SignUpMutationVariables = {
  country_code: COUNTRY_CODE,
  input: SignUpInput,
};

export type SignUpMutation = {
  signUp: string,
};

export type UpdateProfileMutationVariables = {
  country_code: COUNTRY_CODE,
  input: ProfileInput,
};

export type UpdateProfileMutation = {
  updateProfile:  {
    __typename: "Profile",
    company?: string | null,
    email: string,
    family_name: string,
    name: string,
    view?: VIEW | null,
  },
};

export type SetQuoteMutationVariables = {
  country_code: COUNTRY_CODE,
  id: string,
  index: number,
  quote: QuoteInput,
};

export type SetQuoteMutation = {
  setQuote: QUOTE_STATUS,
};

export type RejectQuoteMutationVariables = {
  country_code: COUNTRY_CODE,
  id: string,
  index: number,
  comment: string,
};

export type RejectQuoteMutation = {
  rejectQuote: QUOTE_STATUS,
};

export type SetReportMutationVariables = {
  country_code: COUNTRY_CODE,
  id: string,
  reportIndex?: number | null,
  epcIndex: number,
  input: ReportInput,
};

export type SetReportMutation = {
  setReport:  Array< {
    __typename: "Report",
    date: string,
    text: string,
  } >,
};

export type SetViewMutationVariables = {
  country_code: COUNTRY_CODE,
  view: VIEW,
};

export type SetViewMutation = {
  setView: string,
};

export type GetProfileQueryVariables = {
  country_code: COUNTRY_CODE,
};

export type GetProfileQuery = {
  getProfile?:  {
    __typename: "Profile",
    company?: string | null,
    email: string,
    family_name: string,
    name: string,
    view?: VIEW | null,
  } | null,
};

export type ListProspectsQueryVariables = {
  country_code: COUNTRY_CODE,
  sort?: SORT | null,
  sort_field?: PROSPECT_SORT_FIELD | null,
  status?: STATUS | null,
  from: number,
  size: number,
  search_term?: string | null,
};

export type ListProspectsQuery = {
  listProspects:  {
    __typename: "ListProspects",
    total: number,
    prospects:  Array< {
      __typename: "Prospect",
      id: string,
      timeframe: number,
      address: string,
      postal_code?: string | null,
      type: BUILDING_TYPE,
      expected_date?: string | null,
      occupancy?: OCCUPANCY | null,
      docs?:  Array< {
        __typename: "Doc",
        link: string,
        name: string,
        type: string,
        category?: DOC_CATEGORY | null,
      } > | null,
      remark?: string | null,
      status: STATUS,
      created: string,
      delivery: string,
      polygon?:  Array< {
        __typename: "Polygon",
        lng: number,
        lat: number,
      } > | null,
      location?: Array< number > | null,
      quote:  {
        __typename: "Quote",
        capacity?: number | null,
        cost?: number | null,
        panel_number?: number | null,
        module?: string | null,
        inverter?: string | null,
        inverter_number?: number | null,
        scaffolding?: SCAFFOLDING | null,
        generated?: number | null,
        comment?: string | null,
        docs:  Array< {
          __typename: "Doc",
          link: string,
          name: string,
          type: string,
          category?: DOC_CATEGORY | null,
        } >,
        reports?:  Array< {
          __typename: "Report",
          date: string,
          text: string,
        } > | null,
      },
      index: number,
      comment?: string | null,
    } >,
  },
};

export type GetUploadUrlQueryVariables = {
  input: Array< File >,
};

export type GetUploadUrlQuery = {
  getUploadUrl:  {
    __typename: "Upload",
    url: Array< string | null >,
    link: Array< string | null >,
    name: Array< string >,
  },
};

export type GetProspectQueryVariables = {
  country_code: COUNTRY_CODE,
  id?: string | null,
};

export type GetProspectQuery = {
  getProspect:  {
    __typename: "Prospect",
    id: string,
    timeframe: number,
    address: string,
    postal_code?: string | null,
    type: BUILDING_TYPE,
    expected_date?: string | null,
    occupancy?: OCCUPANCY | null,
    docs?:  Array< {
      __typename: "Doc",
      link: string,
      name: string,
      type: string,
      category?: DOC_CATEGORY | null,
    } > | null,
    remark?: string | null,
    status: STATUS,
    created: string,
    delivery: string,
    polygon?:  Array< {
      __typename: "Polygon",
      lng: number,
      lat: number,
    } > | null,
    location?: Array< number > | null,
    quote:  {
      __typename: "Quote",
      capacity?: number | null,
      cost?: number | null,
      panel_number?: number | null,
      module?: string | null,
      inverter?: string | null,
      inverter_number?: number | null,
      scaffolding?: SCAFFOLDING | null,
      generated?: number | null,
      comment?: string | null,
      docs:  Array< {
        __typename: "Doc",
        link: string,
        name: string,
        type: string,
        category?: DOC_CATEGORY | null,
      } >,
      reports?:  Array< {
        __typename: "Report",
        date: string,
        text: string,
      } > | null,
    },
    index: number,
    comment?: string | null,
  },
};

export type GetAssetsUrlQueryVariables = {
  url: string,
};

export type GetAssetsUrlQuery = {
  getAssetsUrl: string,
};
