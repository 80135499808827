//types
import { AuthProps } from '@components/auth/interfaces/Auth';
import {
    AuthModelReturn,
    CompleteNewPasswordStepProps,
    ForgotPasswordStepProps,
    ForgotPasswordSubmitStepProps,
    HandleAuthStateChangeProps,
    SignInStepProps
} from '@components/auth/interfaces/app/model/authModel';
import { CognitoUserInterface } from '@aws-amplify/ui-components';

//query
import createAccountMutation from '@components/auth/app/model/query/createAccountMutation';
import { COUNTRY_CODE, SignUpInput } from '@interfaces/graphql/API';

/**
 * Auth Model
 *
 * @param {AuthProps} props - The Auth library from aws-amplify.
 * @returns {AuthModelReturn} - The model functions.
 */
const authModel = ({
    lib: {
        external: { API, Auth, Hub, UI_AUTH_CHANNEL, AUTH_STATE_CHANGE_EVENT }
    }
}: AuthProps): AuthModelReturn => {
    /**
     * Helper to change the state of amplify to pass between screen.
     *
     * @param {HandleAuthStateChangeProps} props - The props.
     */
    const handleAuthStateChange = (props: HandleAuthStateChangeProps) => {
        Hub.dispatch(UI_AUTH_CHANNEL, {
            event: AUTH_STATE_CHANGE_EVENT,
            message: props.nextAuthState,
            data: props.authData ? props.authData.user : null
        });
    };

    /**
     * Get current authenticated user with amplify
     *
     * @returns {Promise<CognitoUserInterface>} - The promise resolves to current authenticated
     *   CognitoUser if success.
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const currentAuthenticatedUserStep = async (): Promise<CognitoUserInterface> => {
        return await Auth.currentAuthenticatedUser();
    };

    /**
     * Signs out with amplify
     *
     * @returns {Promise<any>} - The promise resolved if successfully signed out.
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const signOutStep = async (): Promise<any> => {
        return await Auth.signOut();
    };

    /**
     * Signs In with amplify
     *
     * @param {SignInStepProps} props - The username and password.
     * @returns {Promise<CognitoUserInterface>} - The promise resolves the CognitoUser if
     *   successfully signed in.
     */
    const signInStep = async (props: SignInStepProps): Promise<CognitoUserInterface> => {
        return await Auth.signIn(props.username, props.password);
    };

    /**
     * Initiate a forgot password with amplify
     *
     * @param {ForgotPasswordStepProps} props - The username.
     * @returns {Promise<any>} - The promise resolves if successfully initiated.
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const forgotPasswordStep = async (props: ForgotPasswordStepProps): Promise<any> => {
        return await Auth.forgotPassword(props.username);
    };

    /**
     * Confirm a new password using with amplify
     *
     * @param {ForgotPasswordSubmitStepProps} props - The username, code and password.
     * @returns {Promise<string>} - The promise resolves if successfully confirmed.
     */
    const forgotPasswordSubmitStep = async (
        props: ForgotPasswordSubmitStepProps
    ): Promise<string> => {
        return await Auth.forgotPasswordSubmit(props.username, props.code, props.password);
    };

    /**
     * Complete a new password change using with amplify
     *
     * @param {CompleteNewPasswordStepProps} props - The user data and password.
     * @returns {Promise<CognitoUserInterface>} - The promise resolves the CognitoUser if success.
     */
    const completeNewPasswordStep = async (
        props: CompleteNewPasswordStepProps
    ): Promise<CognitoUserInterface> => {
        return await Auth.completeNewPassword(props.userData, props.password);
    };

    /**
     * Create a new account for the user in cognito
     *
     * @param {COUNTRY_CODE} country_code - The country code.
     * @param {SignUpInput} input - The signed up user data.
     * @returns {Promise<string | undefined>} - The promise resolved the CognitoUser.
     */
    const createAccountStep = async (
        country_code: COUNTRY_CODE,
        input: SignUpInput
    ): Promise<string | undefined> => {
        return await createAccountMutation({ API, variables: { country_code, input } });
    };

    return {
        handleAuthStateChange,
        currentAuthenticatedUserStep,
        signOutStep,
        signInStep,
        forgotPasswordStep,
        forgotPasswordSubmitStep,
        completeNewPasswordStep,
        createAccountStep
    };
};

export default authModel;
