//library
import React, { ReactElement } from 'react';
import Image from 'next/image';

const Maintenance = (): ReactElement => (
    <div className="flex flex-col h-screen w-full items-center px-4 justify-center text-elements">
        <Image src="/solarai_logo.png" alt="Solar Ai" width="240" height="55" />
        <p className="text-lg md:text-xl lg:text-2xl text-center mt-6">
            Website under maintenance.
        </p>
        <p className="text-base md:text-lg lg:text-xl text-center mt-2">We will come back soon!</p>
    </div>
);

export default Maintenance;
