//tyeps
import { SignUpMutation } from '@interfaces/graphql/API';
import { CreateAccountMutationProps } from '@components/auth/interfaces/app/model/query/createAccountMutation';
import { GraphQLResult, GRAPHQL_AUTH_MODE } from '@aws-amplify/api';

//library
import { signUp } from '@components/graphql/mutations';

const createAccountMutation = async ({
    API,
    variables
}: CreateAccountMutationProps): Promise<SignUpMutation['signUp']> => {
    const response = (await API.graphql({
        query: signUp,
        variables,
        authMode: GRAPHQL_AUTH_MODE.AWS_IAM
    })) as GraphQLResult<SignUpMutation>;

    return response.data?.signUp || 'OK';
};

export default createAccountMutation;
