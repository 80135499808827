//library
import React, { ReactElement } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

//type
import { SignUpInput } from '@components/auth/interfaces/app/controllers/useControllers';
import { AuthConfig } from '@components/auth/interfaces/Auth';

//code
import Header from '@components/auth/app/view/layout/Header';

/**
 * The sign up form
 *
 * @param {AuthConfig} config - The sign up form props.
 * @returns {ReactElement} - The form.
 */
const SignUpForm: React.FC<AuthConfig> = (config: AuthConfig): ReactElement => {
    const initialValues: SignUpInput = {
        name: '',
        family_name: '',
        email: '',
        company: '',
        password: '',
        confirmpw: '',
        tandc: false
    };

    const { signUp, back, error } = config.controller.useSignUpCtrl();

    return (
        <div slot="sign-up" aria-label="sign-up" className="flex justify-center w-full">
            <div className="divide-y-0 md:w-2/3 lg:w-1/2 mt-24 w-full max-w-2xl">
                <Header sub_title="Sign Up" />
                <div className="pt-12">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={Yup.object({
                            name: Yup.string()
                                .max(50, 'Must be 50 characters or less')
                                .min(2, 'Must be 2 characters or more')
                                .required('Required'),
                            family_name: Yup.string()
                                .max(50, 'Must be 50 characters or less')
                                .min(2, 'Must be 2 characters or more')
                                .required('Required'),
                            email: Yup.string().email('Invalid email address').required('Required'),
                            company: Yup.string()
                                .min(2, 'Must be 2 characters or more')
                                .max(50, 'Must be 50 characters or less'),
                            password: Yup.string()
                                .max(50, 'Must be 50 characters or less')
                                .min(8, 'Must be 8 characters or more')
                                .required('Required'),
                            confirmpw: Yup.string().when('password', {
                                is: (val: string) => (val && val.length > 0 ? true : false),
                                then: Yup.string()
                                    .oneOf(
                                        [Yup.ref('password')],
                                        'Both password need to be the same'
                                    )
                                    .required('Required')
                            }),
                            tandc: Yup.boolean()
                                .required('The terms and conditions must be accepted.')
                                .oneOf([true], 'The terms and conditions must be accepted.')
                        })}
                        onSubmit={async (values, { setSubmitting }) => {
                            await signUp(values);
                            setSubmitting(false);
                        }}>
                        <Form>
                            <div className="bg-white px-12 py-7 shadow-md rounded">
                                <div
                                    className={`text-red-500 italic text-center py-5 ${
                                        error ? 'block' : 'hidden'
                                    }`}>
                                    {error}
                                </div>
                                <div className="grid gap-x-12 gap-y-5 grid-cols-1 md:grid-cols-2">
                                    <div>
                                        <label
                                            className="block text-navy text-base font-medium leading-6"
                                            htmlFor="name">
                                            First Name
                                        </label>
                                        <Field
                                            aria-label="name"
                                            className="shadow appearance-none border rounded w-full h-12 mt-1 pl-4 py-3 font-normal leading-tight focus:outline-none focus:shadow-outline"
                                            name="name"
                                            type="text"
                                            placeholder="First Name"
                                        />
                                        <div className="text-red-500 text-xs italic absolute">
                                            <ErrorMessage name="name" />
                                        </div>
                                    </div>
                                    <div>
                                        <label
                                            className="block text-navy text-base font-medium leading-6"
                                            htmlFor="family_name">
                                            Last Name
                                        </label>
                                        <Field
                                            aria-label="family_name"
                                            className="shadow appearance-none border rounded w-full h-12 mt-1 pl-4 py-3 font-normal leading-tight focus:outline-none focus:shadow-outline"
                                            name="family_name"
                                            type="text"
                                            placeholder="Last Name"
                                        />
                                        <div className="text-red-500 text-xs italic absolute">
                                            <ErrorMessage name="family_name" />
                                        </div>
                                    </div>
                                    <div>
                                        <label
                                            className="block text-navy text-base font-medium leading-6"
                                            htmlFor="email">
                                            Email
                                        </label>
                                        <Field
                                            aria-label="email"
                                            className="shadow appearance-none border rounded w-full h-12 mt-1 pl-4 py-3 font-normal leading-tight focus:outline-none focus:shadow-outline"
                                            name="email"
                                            type="email"
                                            placeholder="Email"
                                            autoComplete="email"
                                        />
                                        <div className="text-red-500 text-xs italic absolute">
                                            <ErrorMessage name="email" />
                                        </div>
                                    </div>
                                    <div>
                                        <label
                                            className="block text-navy text-base font-medium leading-6"
                                            htmlFor="company">
                                            Company (Optional)
                                        </label>
                                        <Field
                                            aria-label="company"
                                            className="shadow appearance-none border rounded w-full h-12 mt-1 pl-4 py-3 font-normal leading-tight focus:outline-none focus:shadow-outline"
                                            name="company"
                                            type="text"
                                            placeholder="Your Company"
                                        />
                                        <div className="text-red-500 text-xs italic absolute">
                                            <ErrorMessage name="company" />
                                        </div>
                                    </div>
                                    <div>
                                        <label
                                            className="block text-navy text-base font-medium leading-6"
                                            htmlFor="password">
                                            Password
                                        </label>
                                        <Field
                                            aria-label="password"
                                            className="shadow appearance-none border rounded w-full h-12 mt-1 pl-4 py-3 font-normal leading-tight focus:outline-none focus:shadow-outline"
                                            name="password"
                                            type="password"
                                            placeholder="Password"
                                            autoComplete="new-password"
                                        />
                                        <div className="text-red-500 text-xs italic absolute">
                                            <ErrorMessage name="password" />
                                        </div>
                                    </div>
                                    <div>
                                        <label
                                            className="block text-navy text-base font-medium leading-6"
                                            htmlFor="confirmpw">
                                            Confirm Password
                                        </label>
                                        <Field
                                            aria-label="confirm_password"
                                            className="shadow appearance-none border rounded w-full h-12 mt-1 pl-4 py-3 font-normal leading-tight focus:outline-none focus:shadow-outline"
                                            name="confirmpw"
                                            type="password"
                                            placeholder="Confirm password"
                                            autoComplete="new-password"
                                        />
                                        <div className="text-red-500 text-xs italic absolute">
                                            <ErrorMessage name="confirmpw" />
                                        </div>
                                    </div>
                                </div>
                                <div className="text-sm text-navy pt-5 font-medium">
                                    <Field
                                        type="checkbox"
                                        name="tandc"
                                        className="shadow border"
                                        aria-label="terms_and_conditions"
                                    />{' '}
                                    I have read and accepted the{' '}
                                    <a
                                        className="underline"
                                        href="https://getsolar.ai/legal/terms-of-use/">
                                        Terms of Use
                                    </a>{' '}
                                    and{' '}
                                    <a href="https://getsolar.ai/legal/privacy/">Privacy Policy</a>
                                    <div className="text-red-500 text-xs italic absolute">
                                        <ErrorMessage name="tandc" />
                                    </div>
                                </div>
                            </div>
                            <div className="flex py-10 justify-between px-2 md:px-0">
                                <button
                                    className="w-36 h-12 px-3  bg-white text-elements text-base font-medium rounded leading-tight hover:shadow-outline focus:shadow-outline border-solid border-2"
                                    onClick={back}
                                    type="button">
                                    Back
                                </button>

                                <button
                                    className="w-36 h-12 px-3 bg-elements text-white text-base font-medium  rounded focus:outline-none leading-tight hover:shadow-outline focus:shadow-outline "
                                    type="submit">
                                    Sign Up
                                </button>
                            </div>
                        </Form>
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default SignUpForm;
