//types
import type { AppProps } from 'next/app';
import { AuthProps } from '@components/auth/interfaces/Auth';
//library
import React, { ReactElement } from 'react';
import { Auth, Hub, API } from 'aws-amplify';
import {
    UI_AUTH_CHANNEL,
    AUTH_STATE_CHANGE_EVENT,
    onAuthUIStateChange
} from '@aws-amplify/ui-components';
import { AmplifyAuthenticator } from '@aws-amplify/ui-react';
import { useRouter } from 'next/router';
//css
import '../styles/tailwind.css';
//code
import AuthStateApp from '@components/auth/AuthStateApp';

import useFormatCountryCode from '@components/helpers/useFormatCountryCode';

/**
 * The app function.
 *
 * @param {AppProps} appProps - The props of the page.
 * @returns {ReactElement} - The page we want to navigate.
 */
function MyApp(appProps: AppProps): ReactElement {
    const props: AuthProps = {
        lib: {
            app: { ...appProps },
            external: {
                API,
                Auth,
                Hub,
                UI_AUTH_CHANNEL,
                AUTH_STATE_CHANGE_EVENT,
                onAuthUIStateChange,
                AmplifyAuthenticator,
                router: useRouter()
            },
            helpers: { useFormatCountryCode }
        }
    };
    return <AuthStateApp {...props} />;
}
// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
// MyApp.getInitialProps = async (appContext: AppContext) => {
//   // calls page's `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(appContext);
//   return { ...appProps }
// }
export default MyApp;
