//library
import { Dispatch, SetStateAction, MouseEvent, useEffect, useReducer, useState } from 'react';
import { AuthState, CognitoUserInterface } from '@aws-amplify/ui-components';

//reducer
import * as store from '@components/auth/app/controllers/store';

//type
import {
    UseControllersReturn,
    UseMainReturn,
    UseSignInReturn,
    UseSignUpReturn,
    SignInInput,
    SignUpInput,
    UseControllersProps,
    UseRedirectReturn,
    ForgotPasswordCodeInput,
    ForgotPasswordChangePasswordInput,
    UseForgotPasswordReturn,
    UseForgotPasswordCodeReturn,
    UseForgotPasswordChangePasswordReturn,
    UseRequestPasswordReturn,
    RequestPasswordInput
} from '@components/auth/interfaces/app/controllers/useControllers';

/**
 * The controller
 *
 * @param {UseControllersProps} props - The models and helper controllers.
 * @returns {UseControllersReturn} - The controller functions.
 */
const useControllers = ({
    model: { authModel },
    lib: {
        external: { onAuthUIStateChange, router },
        helpers: { useFormatCountryCode }
    }
}: UseControllersProps): UseControllersReturn => {
    const [state, dispatch] = useReducer(store.reducer, store.initialState);
    const countryCode = useFormatCountryCode(router.locale);

    const {
        handleAuthStateChange,
        currentAuthenticatedUserStep,
        signOutStep,
        signInStep,
        forgotPasswordStep,
        forgotPasswordSubmitStep,
        completeNewPasswordStep,
        createAccountStep
    } = authModel;

    /**
     * Change the state and the type of the password input
     *
     * @param {MouseEvent} event - The mose click event.
     */
    const displayPassword = (event: MouseEvent): void => {
        event.preventDefault();
        dispatch({
            type: 'SHOW'
        });
    };

    /** Bring the user back to previous(sign in) page */
    const back = () => {
        handleAuthStateChange({ nextAuthState: AuthState.SignIn });
    };

    /**
     * The main controller
     *
     * @returns {UseMainReturn} - The auth state and data.
     */
    const useMainCtrl = (): UseMainReturn => {
        const [userData, setUserData] = useState<CognitoUserInterface | undefined>();
        useEffect(() => {
            let mounted = true; // Indicate the mount state.

            /**
             * Get the user session from currentAuthenticatedUserStep. We double down here because
             * if not we have a problem when we open a new page on the navigator.
             */
            const getUserSession = async () => {
                try {
                    dispatch({ type: 'FETCH' });
                    const user: CognitoUserInterface = await currentAuthenticatedUserStep();
                    handleAuthStateChange({ nextAuthState: AuthState.SignedIn, authData: user });
                    dispatch({
                        type: 'CANCEL'
                    });
                } catch {
                    handleAuthStateChange({ nextAuthState: AuthState.SignIn });
                    dispatch({
                        type: 'CANCEL'
                    });
                }
            };

            //get the manually session because sometime amplify don't fire it on refresh.
            getUserSession();

            onAuthUIStateChange((nextAuthState, authData) => {
                dispatch({
                    type: 'SUCCESS',
                    data: {
                        mounted: mounted,
                        authState: nextAuthState,
                        authData: authData as CognitoUserInterface
                    }
                });
            });

            return () => {
                mounted = false;
            };
        }, []);

        return {
            authState: state.authState,
            authData: state.authData,
            loading: state.loading,
            userData,
            setUserData
        };
    };

    /**
     * The controller for sign in
     *
     * @param {Dispatch<SetStateAction<CognitoUserInterface | undefined>>} setUserData - The
     *   function to set user data.
     * @returns {UseSignInReturn} - The sign in and other accompanying functions.
     */
    const useSignInCtrl = (
        setUserData: Dispatch<SetStateAction<CognitoUserInterface | undefined>>
    ): UseSignInReturn => {
        /** Changes the state to forgot password */
        const forgotPassword = () => {
            dispatch({
                type: 'CANCEL'
            });
            handleAuthStateChange({ nextAuthState: AuthState.ForgotPassword });
        };

        /**
         * Sign in with amplify
         *
         * @param {SignInInput} values The username and pasword
         */
        const signIn = async (values: SignInInput) => {
            try {
                dispatch({
                    type: 'CANCEL'
                });
                handleAuthStateChange({ nextAuthState: AuthState.Loading });
                values.username = values.username.trim().toLowerCase();
                const user: CognitoUserInterface = await signInStep({
                    username: values.username,
                    password: values.password
                });
                if (user.challengeName == 'NEW_PASSWORD_REQUIRED') {
                    setUserData(user);
                    handleAuthStateChange({
                        nextAuthState: AuthState.ResetPassword,
                        authData: user
                    });
                    return;
                }
                handleAuthStateChange({ nextAuthState: AuthState.SignedIn, authData: user });
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error: any) {
                handleAuthStateChange({ nextAuthState: AuthState.SignIn });
                switch (error.code) {
                    case 'NotAuthorizedException':
                        dispatch({
                            type: 'ERROR',
                            data: {
                                error: error.message
                            }
                        });
                        break;
                    default:
                        dispatch({
                            type: 'ERROR',
                            data: {
                                error: 'An error occurred. Please try again.'
                            }
                        });
                }
            }
        };

        /** Bring the user to sign up page */
        const signUp = () => {
            handleAuthStateChange({ nextAuthState: AuthState.SignUp });
        };

        return {
            error: state.error,
            signIn,
            signUp,
            displayPassword,
            forgotPassword,
            showNewPassword: state.showNewPassword
        };
    };

    /**
     * The controller for sign up
     *
     * @returns {UseSignUpReturn} - The sign up and other accompanying functions.
     */
    const useSignUpCtrl = (): UseSignUpReturn => {
        /**
         * Sign in with amplify
         *
         * @param {SignUpInput} values The user info.
         */
        const signUp = async (values: SignUpInput) => {
            try {
                dispatch({
                    type: 'CANCEL'
                });
                handleAuthStateChange({ nextAuthState: AuthState.Loading });

                const { name, family_name, email, password, company } = values;

                await createAccountStep(countryCode, {
                    name: name.trim().toLowerCase(),
                    family_name: family_name.trim().toLowerCase(),
                    email: email.trim().toLowerCase(),
                    password,
                    company
                });

                // Sign the user in
                const authUser: CognitoUserInterface = await signInStep({
                    username: email,
                    password
                });

                handleAuthStateChange({ nextAuthState: AuthState.SignedIn, authData: authUser });

                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error: any) {
                handleAuthStateChange({ nextAuthState: AuthState.SignUp });

                switch (error.errors?.[0]?.message) {
                    case 'UsernameExistsException':
                        dispatch({
                            type: 'ERROR',
                            data: {
                                error: 'User already exists. Please sign in.'
                            }
                        });
                        break;
                    default:
                        dispatch({
                            type: 'ERROR',
                            data: {
                                error: 'An error occurred. Please try again.'
                            }
                        });
                }
            }
        };

        return { signUp, back, error: state.error };
    };

    /**
     * The controller for redirect
     *
     * @returns {UseRedirectReturn} - The redirect and hasAccess function.
     */
    const useRedirectCtrl = (): UseRedirectReturn => {
        /** Redirect the user. */
        const redirect = async () => {
            dispatch({
                type: 'FETCH'
            });
            await signOutStep();
            window.location.href = 'https://search.getsolar.ai';
        };

        /**
         * Check the authData for whether the user can access the app.
         *
         * @param {CognitoUserInterface} authData - The user data.
         * @returns {boolean} - Whether user has access or not.
         */
        const hasAccess = (authData: CognitoUserInterface): boolean => {
            const allowedGroups = (process.env.NEXT_PUBLIC_ALLOWED_GROUPS &&
                process.env.NEXT_PUBLIC_ALLOWED_GROUPS.split(',')) || ['admin'];
            return authData.signInUserSession.accessToken.payload['cognito:groups'].some(
                (group: string) => allowedGroups.includes(group)
            );
        };

        return { redirect, hasAccess };
    };

    /**
     * The controller for forgot password code
     *
     * @param {(value?: ForgotPasswordCodeInput, error?: string) => void} onSubmit - The onSubmit function.
     * @returns {UseForgotPasswordCodeReturn} - The forgotPasswordCode and back function.
     */
    const useForgotPasswordCodeCtrl = (
        onSubmit: (value?: ForgotPasswordCodeInput, error?: string) => void
    ): UseForgotPasswordCodeReturn => {
        /**
         * Submit the email to send the ForgotPassword code from cognito to the user email.
         *
         * @param {ForgotPasswordCodeInput} values - The email
         */
        const forgotPasswordCode = async (values: ForgotPasswordCodeInput) => {
            try {
                onSubmit();
                values.username = values.username.trim().toLowerCase();
                await forgotPasswordStep({ username: values.username });
                onSubmit(values);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error: any) {
                switch (error.code) {
                    case 'LimitExceededException':
                        onSubmit(undefined, error.message);
                        break;
                    default:
                        onSubmit(undefined, 'An error occurred. Please try again.');
                }
            }
        };

        return { forgotPasswordCode, back };
    };

    /**
     * The controller for forgot password change password
     *
     * @param {(value?: ForgotPasswordCodeInput, error?: string) => void} onSubmit - The onSubmit function.
     * @returns {UseForgotPasswordChangePasswordReturn} - The forgotPasswordChangePassword and back function.
     */
    const useForgotPasswordChangePasswordCtrl = (
        onSubmit: (value?: ForgotPasswordChangePasswordInput, error?: string) => void
    ): UseForgotPasswordChangePasswordReturn => {
        /**
         * Submit the new password to cognito.
         *
         * @param {ForgotPasswordChangePasswordInput} values - The code and a new password.
         */
        const forgotPasswordChangePassword = async (values: ForgotPasswordChangePasswordInput) => {
            try {
                onSubmit();
                const { username, code, password } = values;
                handleAuthStateChange({ nextAuthState: AuthState.Loading });
                await forgotPasswordSubmitStep({ username, code: code.trim(), password });
                handleAuthStateChange({ nextAuthState: AuthState.SignIn });
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error: any) {
                onSubmit(undefined, 'An error occurred. Please try again.');
            }
        };

        return { forgotPasswordChangePassword, back };
    };

    /**
     * The controller for forgot password
     *
     * @returns {UseForgotPasswordReturn} - The forgotPassword function.
     */
    const useForgotPasswordCtrl = (): UseForgotPasswordReturn => {
        /**
         * Set the differents step of the forgot password.
         *
         * @param {ForgotPasswordCodeInput | ForgotPasswordChangePasswordInput} values - The value
         *   of the step. We use it to change between step 1 to 2
         * @param {string} error - The error if one of the cognito call send one back
         */
        const forgotPassword = async (
            values?: ForgotPasswordCodeInput | ForgotPasswordChangePasswordInput,
            error?: string
        ) => {
            if (values?.state == 'code') {
                handleAuthStateChange({ nextAuthState: AuthState.Loading });
                dispatch({
                    type: 'SUCCESS',
                    data: {
                        username: values.username,
                        step: 1
                    }
                });
                handleAuthStateChange({ nextAuthState: AuthState.ForgotPassword });
            }

            if (error) {
                dispatch({
                    type: 'ERROR',
                    data: {
                        error: error
                    }
                });
            }
        };

        return { forgotPassword, username: state.username, step: state.step, error: state.error };
    };

    /**
     * The controller for request password
     *
     * @param {CognitoUserInterface} userData - The user data.
     * @returns {UseRequestPasswordReturn} - The changePassword and accompanying functions.
     */
    const useRequestPasswordCtrl = (userData: CognitoUserInterface): UseRequestPasswordReturn => {
        /**
         * Change new password with amplify
         *
         * @param {RequestPasswordInput} values - The new password
         */
        const changePassword = async (values: RequestPasswordInput) => {
            try {
                dispatch({
                    type: 'CANCEL'
                });
                handleAuthStateChange({ nextAuthState: AuthState.Loading });

                const user: CognitoUserInterface = await completeNewPasswordStep({
                    userData,
                    password: values.password
                });

                handleAuthStateChange({ nextAuthState: AuthState.SignedIn, authData: user });

                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error: any) {
                handleAuthStateChange({ nextAuthState: AuthState.SignIn });
                switch (error.code) {
                    case 'NotAuthorizedException':
                        dispatch({
                            type: 'ERROR',
                            data: {
                                error: error.message
                            }
                        });
                        break;
                    default:
                        dispatch({
                            type: 'ERROR',
                            data: {
                                error: 'An error occurred. Please try again.'
                            }
                        });
                }
            }
        };

        return {
            changePassword,
            displayPassword,
            error: state.error,
            showNewPassword: state.showNewPassword
        };
    };

    return {
        useMainCtrl,
        useSignInCtrl,
        useSignUpCtrl,
        useRedirectCtrl,
        useForgotPasswordCodeCtrl,
        useForgotPasswordChangePasswordCtrl,
        useForgotPasswordCtrl,
        useRequestPasswordCtrl
    };
};

export default useControllers;
