/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const signUp = /* GraphQL */ `
    mutation SignUp($country_code: COUNTRY_CODE!, $input: SignUpInput!) {
        signUp(country_code: $country_code, input: $input)
    }
`;
export const updateProfile = /* GraphQL */ `
    mutation UpdateProfile($country_code: COUNTRY_CODE!, $input: ProfileInput!) {
        updateProfile(country_code: $country_code, input: $input) {
            company
            email
            family_name
            name
            view
        }
    }
`;
export const setQuote = /* GraphQL */ `
    mutation SetQuote(
        $country_code: COUNTRY_CODE!
        $id: String!
        $index: Int!
        $quote: QuoteInput!
    ) {
        setQuote(country_code: $country_code, id: $id, index: $index, quote: $quote)
    }
`;
export const rejectQuote = /* GraphQL */ `
    mutation RejectQuote(
        $country_code: COUNTRY_CODE!
        $id: String!
        $index: Int!
        $comment: String!
    ) {
        rejectQuote(country_code: $country_code, id: $id, index: $index, comment: $comment)
    }
`;
export const setReport = /* GraphQL */ `
    mutation SetReport(
        $country_code: COUNTRY_CODE!
        $id: String!
        $reportIndex: Int
        $epcIndex: Int!
        $input: ReportInput!
    ) {
        setReport(
            country_code: $country_code
            id: $id
            reportIndex: $reportIndex
            epcIndex: $epcIndex
            input: $input
        ) {
            date
            text
        }
    }
`;
export const setView = /* GraphQL */ `
    mutation SetView($country_code: COUNTRY_CODE!, $view: VIEW!) {
        setView(country_code: $country_code, view: $view)
    }
`;
